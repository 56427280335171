import { useEffect, useState } from 'react'
import { BehaviorSubject } from 'rxjs'
import { List } from 'src/types/entities'
import { supabaseClient } from 'src/utils/supabase'

const lists$ = new BehaviorSubject([])

export function useListStore(initialLists: Partial<List>[]) {
  const [lists, setLists] = useState(initialLists)

  useEffect(() => {
    lists$.next(initialLists as never[])
  }, [initialLists])

  useEffect(() => {
    lists$.subscribe((lists) => setLists([...lists]))
  }, [])

  const addList = (list: List) => {
    lists$.next([list, ...lists] as never[])
  }

  const removeList = (list: List) => {
    const index = lists.findIndex((t) => t.id === list.id)
    if (-1 !== index) {
      lists.splice(index, 1)
      lists$.next(lists as never[])
    }
  }

  const updateList = (list: List) => {
    const index = lists.findIndex((t) => t.id === list.id)
    if (-1 !== index) {
      lists.splice(index, 1, list)
      lists$.next(lists as never[])
    }
  }

  const searchLists = async (search: string) => {
    const { data, error } = await supabaseClient
      .from('lists')
      .select(`id,name, tasks(count)`)
      .ilike('name', `%${search}%`)

    if (error || null === data) {
      lists$.next([])
    } else if (undefined !== data) {
      lists$.next(data as never[])
    }
  }

  return [
    {
      lists: lists,
      addList,
      updateList,
      removeList,
      searchLists
    }
  ]
}
