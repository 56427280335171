import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import {
  Box,
  Button,
  Card,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material'
import React, {useState} from 'react'
import {TaskListProps} from 'src/types/props'

export default function TaskList({
                                   tasks,
                                   setEditTask,
                                   setOpenEdition,
                                   onDelete,
                                   onEditionFormTitle
                                 }: TaskListProps) {
  const [hasDeletion, setHasDeletion] = useState<boolean>(false)
  return (
    <Card>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Tâche</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Liste</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tasks?.map((task) => {
              return (
                <TableRow hover key={task.id}>
                  <TableCell>
                    <Box
                      sx={{
                        alignItems: 'center',
                        display: 'flex'
                      }}>
                      <Typography color="textPrimary" variant="body1">
                        {task.name}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box
                      sx={{
                        alignItems: 'center',
                        display: 'flex'
                      }}>
                      <Typography color="textPrimary" variant="body1">
                        {task.description}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box
                      sx={{
                        alignItems: 'center',
                        display: 'flex'
                      }}>
                      <Typography color="textPrimary" variant="body1">
                        {task.list.name}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box
                      sx={{
                        alignItems: 'center',
                        display: 'flex'
                      }}>
                      <Typography color="textPrimary" variant="body1">
                        {task.completed ? 'DONE' : 'WAITING'}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Grid container spacing={1}>
                      <Grid item>
                        <Button
                          onClick={() => {
                            setEditTask(task)
                            setOpenEdition(true)
                            onEditionFormTitle(`Edition de la tâche #${task.id}`)
                          }}>
                          <EditIcon/>
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          disabled={hasDeletion}
                          onClick={async () => {
                            setHasDeletion(true)
                            await onDelete(task)
                            setHasDeletion(false)
                          }}>
                          <DeleteIcon/>
                        </Button>
                      </Grid>
                    </Grid>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  )
}
