import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import * as React from 'react'
import {FormModalProps} from 'src/types/props/formModalProps'
import {CircularLoader} from "src/components/atoms/loader";
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from '@mui/icons-material/Close';

export function FormModal({
                                    title,
                                    open,
                                    onCancel,
                                    onSubmit,
                                    cancelLabel,
                                    submitLabel,
                                    closeOnEscapeKeyDonwPress,
                                    submitState,
                                    children,
                                    ...others
                                  }: FormModalProps) {
  return (
    <div>
      <Dialog
        open={open}
        onClose={onCancel}
        scroll="paper"
        disableEscapeKeyDown={!closeOnEscapeKeyDonwPress}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        {...others}>
        <DialogTitle id="scroll-dialog-title">{title}</DialogTitle>
        <DialogContent dividers>
          <form id="form" onSubmit={onSubmit}>{children}</form>
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancel}>
            <CloseIcon/>
            <span style={{ marginLeft: '5px' }}>{cancelLabel}</span>
          </Button>
          <Button form="form" type="submit">
            {submitState ?
              <CircularLoader/>
              : <>
                <SaveIcon />
                <span style={{ marginLeft: '5px' }}>{submitLabel}</span>
              </>
            }
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
