import AddTaskIcon from '@mui/icons-material/AddTask';
import {
Box,
Button,
Card,
CardContent,
InputAdornment,
SvgIcon,
TextField,
Typography
} from '@mui/material';
import React,{ useEffect,useState } from 'react';
import { useDebounce } from "src/hooks";
import { SearchIcon } from 'src/icons/search';
import { TaskToolbarProps } from "src/types/props";

export const TaskToolBar = ({onAdd, onSearch, ...others} : TaskToolbarProps) => {
  const [search, setSearch] = useState<string>('')
  const { debouncedValue } = useDebounce<string>(search, 500)

  useEffect(() => {
    (async () => {
      await onSearch(debouncedValue)
    })()
  }, [debouncedValue])

  return(
    <Box
      {...others}
      sx={{
        marginBottom: '2.8em'
      }}>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
          m: -1,
          marginTop: '2.8em'
        }}>
        <Typography sx={{m: 1}} variant="h4">
          Liste de vos tâches
        </Typography>
      </Box>
      <Box sx={{mt: 3}}>
        <Card>
          <CardContent
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'space-between',
              flexWrap: 'wrap'
            }}>
            <Box sx={{maxWidth: '550px', width: '100%'}}>
              <TextField
                onKeyUp={(event) => {
                  setSearch((event.target as HTMLInputElement).value)
                }}
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon color="action" fontSize="small">
                        <SearchIcon/>
                      </SvgIcon>
                    </InputAdornment>
                  )
                }}
                placeholder="Rechercher une tâche"
                variant="outlined"
              />
            </Box>
            <Box sx={{m: 1}}>
              <Button
                onClick={onAdd}
                color="primary"
                variant="contained">
                <AddTaskIcon/>
              </Button>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </Box>
  )
}

