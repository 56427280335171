import { zodResolver } from '@hookform/resolvers/zod'
import { Grid, MenuItem, Select, TextField, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React, { useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useSelect } from 'react-supabase'
import ErrorMessage from 'src/components/atoms/error/ErrorMessage'
import {LinearLoader} from 'src/components/atoms/loader'
import Toast from 'src/components/atoms/toast/Toast'
import {FormModal} from 'src/components/molecules/modal'
import { Layout } from 'src/components/organisms/layouts'
import { TaskToolBar } from 'src/components/organisms/task'
import TaskList from 'src/components/organisms/task/TaskList'
import { CrudModeEnum, ToastTypeEnum } from 'src/enums'
import { useTaskStore } from 'src/store'
import { useListStore } from 'src/store/listStore'
import { List, Task, taskSchema } from 'src/types/entities'
import { TaskInputs } from 'src/types/inputs/taskInputs'
import { supabaseClient } from 'src/utils/supabase'

const useStyles = makeStyles({
  containerWraper: {
    marginTop: '2.8em'
  },
  label: {
    margin: '0.8em 0.2em',
    display: 'inline-block'
  }
})

export default function Tasks() {
  const classes = useStyles()
  const [open, setOpen] = useState<boolean>(false)
  const [submitting, setSubmitting] = useState<boolean>(false)
  const [openToast, setOpenToast] = useState<boolean>(false)
  const [crudMode, setCrudMode] = useState<CrudModeEnum>(CrudModeEnum.READ)
  const [toastType, setToastType] = useState<ToastTypeEnum>(ToastTypeEnum.DEFAULT)
  const [message, setMessage] = useState<string>('')
  const [task, setTask] = useState<Partial<Task>>()
  const [formTitle, setFormTitle] = useState<string>('Créer une nouvelle tâche')
  const [{ data, error, fetching: loading }] = useSelect<Partial<Task>>('tasks', {
    columns: `id,name, description, list:lists(id,name)`
  })
  const [{ data: listsData, error: listError, fetching: loadingList }] = useSelect<Partial<List>>(
    'lists',
    {
      columns: `id, name`
    }
  )

  const [{ tasks, addTask, updateTask, removeTask, searchTasks }] = useTaskStore(data || [])
  const [{ lists }] = useListStore(listsData || [])

  const {
    register,
    setValue,
    handleSubmit,
    watch,
    formState: { errors }
  } = useForm<TaskInputs>({
    resolver: zodResolver(taskSchema)
  })

  useEffect(() => {
    if (undefined !== task) {
      setValue('name', task.name!)
      setValue('description', task.description!)
      setValue('list_id', task.list.id!)
    }
  }, [data, task])

  const onSubmit: SubmitHandler<TaskInputs> = async (data: any) => {
    setSubmitting(true)
    try {
      if (crudMode === CrudModeEnum.CREATION) {
        const { data: task, error } = await createTask(data)
        if (error) {
          throw new Error(error.message)
        } else {
          addTask({
            ...(task[0] as unknown as Task),
            list: lists?.find((list) => list.id === data.list_id)
          })
          setOpenToast(true)
          setToastType(ToastTypeEnum.SUCCESS)
          setMessage('La tâche ' + data.name + 'a bien été créée')
          resetForm()
        }
      } else if (crudMode === CrudModeEnum.EDITION) {
        const { data: updatedTask, error } = await update(data)
        if (error) {
          throw new Error(error.message)
        } else {
          updateTask({
            ...(updatedTask[0] as unknown as Task),
            list: lists?.find((list) => list.id === data.list_id)
          })
          setOpenToast(true)
          setToastType(ToastTypeEnum.SUCCESS)
          setMessage('La tâche ' + data.name + 'a bien été modifiée')
        }
      }
    } catch (error: any) {
      setOpenToast(true)
      setToastType(ToastTypeEnum.ERROR)
      setMessage("Une erreur est survénue lors de l'enregistrement des données")
      setSubmitting(false)
      setCrudMode(CrudModeEnum.READ)
      console.error(error.message)
    } finally {
      setSubmitting(false)
      setCrudMode(CrudModeEnum.READ)
      setOpen(false)
    }
  }

  const createTask = async (data: Partial<Task>) => {
    return supabaseClient.from('tasks').insert(data, {
      returning: 'representation'
    })
  }

  const update = async (data: Partial<Task>) => {
    return supabaseClient
      .from('tasks')
      .update(data, {
        returning: 'representation'
      })
      .match({ id: task?.id! })
  }

  const resetForm = () => {
    setTask(undefined)
    setValue('name', '')
    setValue('description', '')
    setValue('list_id', 0)
  }

  const deleteTask = async (task: Partial<Task>) => {
    setSubmitting(true)
    const { error } = await supabaseClient
      .from('tasks')
      .delete({
        returning: 'minimal'
      })
      .match({ id: task.id })

    if (error) {
      setOpenToast(true)
      setToastType(ToastTypeEnum.ERROR)
      setMessage('Une erreur est survénue lors de la suppression de la tâche ' + task.name)
      console.error(error.message)
      setSubmitting(false)
    } else {
      removeTask(task as Task)
      setOpenToast(true)
      setToastType(ToastTypeEnum.SUCCESS)
      setMessage('La tâche ' + task.name + 'a bien été supprimée')
      setSubmitting(false)
    }
  }

  const handleClose = (_event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }
    setOpenToast(false)
    setToastType(ToastTypeEnum.DEFAULT)
  }
  if (error || listError) {
    return <ErrorMessage error={error || listError} resetErrorBoundary={() => {}} />
  }

  return (
    <Layout>
      {loading || loadingList ? (
        <LinearLoader />
      ) : (
        <>
          <TaskToolBar
            onSearch={async (value: string) => await searchTasks(value)}
            onAdd={() => {
              setFormTitle('Créer une nouvelle tâche')
              setOpen(true)
              setCrudMode(CrudModeEnum.CREATION)
              resetForm()
            }}
          />
          <TaskList
            onEditionFormTitle={setFormTitle}
            setOpenEdition={() => {
              setOpen(true)
              setCrudMode(CrudModeEnum.EDITION)
            }}
            setEditTask={setTask}
            tasks={tasks!}
            onDelete={async (task) => {
              await deleteTask(task)
            }}
          />
          <FormModal
            title={formTitle}
            open={open}
            closeOnEscapeKeyDonwPress={false}
            onCancel={(_, reason) => {
              if (reason !== 'backdropClick') {
                setOpen(false)
                setCrudMode(CrudModeEnum.READ)
              }
            }}
            onSubmit={handleSubmit(onSubmit)}
            cancelLabel="Annuler"
            submitLabel="Enregister"
            submitState={submitting}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <label className={classes.label} htmlFor="name">
                  Tâche
                </label>
                <TextField
                  error={errors.name?.message !== undefined}
                  helperText={errors.name?.message ?? ''}
                  fullWidth
                  value={watch('name') || task?.name}
                  {...register('name')}
                  type="text"
                  placeholder="Tâche à faire"
                  variant="outlined"
                  id="name"
                />
              </Grid>
              <Grid item xs={12}>
                <label className={classes.label} htmlFor="description">
                  Description
                </label>
                <TextField
                  error={errors.description?.message !== undefined}
                  helperText={errors.description?.message ?? ''}
                  fullWidth
                  value={watch('description') || task?.description}
                  {...register('description')}
                  type="text"
                  placeholder="Décrivez la tâche"
                  variant="outlined"
                  id="description"
                />
              </Grid>
              <Grid item xs={12}>
                <label className={classes.label} htmlFor="list">
                  Listes
                </label>
                <Select
                  error={errors.list_id?.message !== undefined}
                  fullWidth
                  id="list"
                  value={watch('list_id')}
                  {...register('list_id')}>
                  {lists?.map((list) => (
                    <MenuItem
                      key={list.id}
                      selected={(watch('list_id') || task?.list?.id) === list.id}
                      value={list.id}>
                      {list.name}
                    </MenuItem>
                  ))}
                </Select>
                <Typography
                  sx={{
                    color: '#D14343',
                    fontSize: '0.75rem',
                    marginLeft: '14px',
                    marginTop: '4px'
                  }}>
                  {errors.list_id?.message}
                </Typography>
              </Grid>
            </Grid>
          </FormModal>
          <Toast
            positionX="right"
            positionY="top"
            timer={3000}
            onClose={handleClose}
            open={openToast}
            severity={toastType}
            sx={{ width: '100%' }}>
            {message}
          </Toast>
        </>
      )}
    </Layout>
  )
}
