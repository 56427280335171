import { useEffect, useState } from 'react'
import { useSelect } from 'react-supabase'
import { BehaviorSubject } from 'rxjs'
import { Task } from 'src/types/entities'
import { supabaseClient } from 'src/utils/supabase'

const tasks$ = new BehaviorSubject([])

export function useTaskStore(initialTasks: Partial<Task>[]) {
  const [tasks, setTasks] = useState<Partial<Task>[]>([])

  useEffect(() => {
    tasks$.next(initialTasks as never[])
  }, [initialTasks])

  useEffect(() => {
    tasks$.subscribe((newTasks) => {
      setTasks([...newTasks])
    })
  }, [])

  const addTask = (task: Task) => {
    tasks$.next([task, ...tasks] as never[])
  }

  const removeTask = (task: Task) => {
    const index = tasks.findIndex((t) => t.id === task.id)
    if (-1 !== index) {
      tasks.splice(index, 1)
      tasks$.next(tasks as never[])
    }
  }

  const updateTask = (task: Task) => {
    const index = tasks.findIndex((t) => t.id === task.id)
    if (-1 !== index) {
      tasks.splice(index, 1, task)
      tasks$.next([...tasks] as never[])
    }
  }

  const searchTasks = async (search: string) => {
    const { data, error } = await supabaseClient
      .from('tasks')
      .select(`id,name, description, list:lists(id,name)`)
      .ilike('name', `%${search}%`)

    if (error) {
      tasks$.next([])
    } else {
      tasks$.next(data as never[])
    }
  }
  const filterByStatus = async (status: boolean) => {
    const [{ data, error, fetching: loading }] = useSelect<Partial<Task>>('tasks', {
      columns: `id,name, description, list:lists(id,name)`,
      filter: (query) => {
        query.is('completed', status)
        return query
      }
    })
    if (error || null === data) {
      tasks$.next([])
    } else if (!loading && undefined !== data) {
      tasks$.next(data as never[])
    }
  }

  return [
    {
      tasks,
      addTask,
      updateTask,
      removeTask,
      searchTasks,
      filterByStatus
    }
  ]
}
